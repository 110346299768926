import styled from 'styled-components';
import { variables } from '../GlobalStyles';

const StyledTableOfContents = styled.nav`
  position: fixed;
  top: 3rem;
  left: 0;
  right: 0;
  background: ${variables.white};
  /* border-bottom: 0.25px solid ${variables.grey400}; */
  z-index: 200;
  > div {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ol {
    margin: 0;
    padding: 0.25rem 1.5rem;
    display: flex;
    gap: 0.5rem;
    overflow-y: hidden;
    li {
      list-style: none;
      border-radius: 0.25rem;
      a {
        color: ${variables.black};
        font-size: 0.875rem;
        padding: 0.125rem 0.5rem;
        height: 100%;
        display: inline-block;
      }
      &:hover {
        background: ${props => props.brandColor100};
        a {
          color: ${props => props.brandColor900};
        }
      }
    }
  }

  @media (max-width: ${variables.sm}) {
    display: none;
  }
  @media (max-width: ${variables.lg}) {
    .num, .shorten {
      display: none;
    }
  }
`;

export default StyledTableOfContents;