import styled from 'styled-components';
import { BoxCard } from './Card';
import { variables } from '../GlobalStyles';

const Summary = styled(BoxCard)`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-gap: 2rem;
  .description {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: ${variables.sm}) {
    display: flex;
    flex-direction: column;
  }
`;

const SummaryItem = styled.div`
  grid-row: ${props => props.rows};
  grid-column: ${props => props.columns};
  justify-self: ${props => props.justify};
  align-self: ${props => props.align};
`;

export { 
  Summary,
  SummaryItem,
};