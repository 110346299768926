import React from 'react';
import styled from 'styled-components';
import { variables } from '../../../styles/GlobalStyles';

const ColorSwatch = (props) => (
  <ColorSwatchStyles hex={props.hex} outline={props.outline} >
    <div className='swatch'></div>
    <p className='name'>
      <span>{props.name}</span>
      <span>{props.hex}</span>
    </p>
  </ColorSwatchStyles>
);

const ColorSwatchStyles = styled.li`
  list-style: none;
  .swatch {
    width: 100%;
    height: 5rem;
    background-color: ${props => props.hex};
    border-radius: ${variables.brstandard};
    ${props => props.outline && `outline: 0.25px solid ${props.outline}`};
  }
  .name {
    font-family: 'SF Mono', monospace;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
    span {
      display: block;
    }
  }
`;

export default ColorSwatch;