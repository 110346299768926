import styled, { css } from 'styled-components';
import { variables } from '../GlobalStyles';

const GridStyles = css`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-gap: 2rem;
  margin: 1.5rem 0;

  ${props => props.mt && `margin-top: ${props.mt};`};
  ${props => props.mb && `margin-bottom: ${props.mb};`};
  ${props => props.gap && `grid-gap: ${props.gap};`};
  ${props => props.background === 'grey100' && `background: ${variables.grey100};`};
  ${props => props.padding && `padding: ${props.padding};`};
  ${props => props.align && `align-items: ${props.align};`};

  @media (max-width: ${variables.md}) {
    grid-template-columns: ${props => props.mdColumns ? `repeat(${props.mdColumns}, 1fr)` : `1fr 1fr`};
  }
  @media (max-width: ${variables.sm}) {
    grid-template-columns: ${props => props.smColumns ? `repeat(${props.smColumns}, 1fr)` : `1fr`};
    grid-gap: ${props => props.smGap ? `${props.smGap}` : `1rem`};
  }
`;

const Grid = styled.div`
  ${GridStyles}
`;

const GridList = styled.ul`
  ${GridStyles}
`

const GridChild = styled.div`
  grid-row: ${props => props.rows};
  grid-column: ${props => props.columns};
  justify-self: ${props => props.justify};
  align-self: ${props => props.align};
  @media (max-width: ${variables.md}) {
    grid-column: ${props => props.mdColumns};
  }
  @media (max-width: ${variables.sm}) {
    grid-column: ${props => props.smColumns};
  }
`;

export {
  Grid,
  GridList,
  GridChild,
};
  