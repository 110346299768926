import styled from 'styled-components';
import { variables } from '../GlobalStyles';

const CodeBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1.125rem;
  justify-content: center;
  align-items: flex-start;
  border-radius: ${variables.brstandard};
  background: ${variables.grey900};
  width: 100%;
  align-self: flex-start;
  p {
    font-family: 'SF Mono', monospace;
    margin: 0;
    color: ${variables.white};
    .property {
      color: #3FD5F5;
    }
    .comment {
      color: ${variables.grey400};
    }
  }
  @media (max-width: ${variables.sm}) {
    width: 100%;
  }
`;

export default CodeBlock;