import styled from 'styled-components';
import { variables } from '../GlobalStyles';

const CaseStudyStyles = styled.div`
  margin: 0 auto;
  .text-container {
    padding-top: 6rem;
    scroll-margin-top: 2rem;
    &:first-of-type {
      padding-top: 1rem;
      scroll-margin-top: 7rem;
    }
  }
  .image-container {
    max-width: 1500px;
    margin: 2rem auto;
    img {
      border-radius: ${variables.brstandard};
      border: 0.5px solid ${variables.grey400};
    }
    p {
      font-size: 0.875rem;
      color: ${variables.grey900};
      text-align: center;
      margin-top: 1rem;
    }
  }
  .image-container.borderless {
    img {
      border: none;
    }
  }

  .warning-block {
    .headline {
      font-family: sans-serif;
    }
  }

  .video {
    display: flex;
    justify-content: center;
    max-width: 300px;
    width: 100%;
    justify-self: center;
    video {
      max-width: 300px;
      width: 100%;
      border-radius: ${variables.brstandard};
    }
  }
  .asset.small {
    img {
      width: 80%;
      margin: 0 auto;
    }
  }
  @media (max-width: ${variables.sm}) {
    grid-template-columns: 1fr;
    .asset {
      grid-row: 1 / 2;
      .video {
        max-width: 100%;
        video {
          max-width: 100%;
        }
      }
    }
    .text {
      grid-row: 2 / 3;
    }
  }

  h1, h2, p, li {
    color: ${variables.black};
  }
  h2.page-heading {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: ${props => props.heading};
  }
  h2 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: ${variables.grey900};
    display: flex;
    align-items: center;
    .number {
      margin-right: 0.75rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      border-radius: 0.25rem;
      color: ${props => props.brandColor900};
      background: ${props => props.brandColor100};
    }
  }
  h3.subheading {
    text-transform: none;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.75rem;
    line-height: 2.5rem;
    font-weight: 600;
    color: ${props => props.heading};
  }
  h4.subheading {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  h5 {
    font-size: 1.25rem;
    font-weight: 500;
    margin-top: 1rem;
  }
  p {
    margin-top: 1rem;
  }
  ul {
    list-style-type: initial;
  }
  .iframe-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
  }
  iframe {
    border: 0.25px solid ${variables.black};
    width: 380px;
    height: 740px;
    border-radius: ${variables.brstandard};
  }

  .video-container {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    video {
      max-width: 300px;
      width: 100%;
      border-radius: ${variables.brstandard};
    }
  }

  @media (max-width: ${variables.sm}) {
    .image-and-link {
      grid-template-columns: 1fr;
    }
    .iframe-container {
      iframe {
        width: 100%;
        height: 740px;
      }
    }    
  }

  .dynamic-color {
    width: 184px;
    height: 48px;
  }

  .border-top-1 {
    border-top: 0.5px solid ${variables.black};
    padding-top: 1.5rem;
  }

  /* CUSTOM MARGIN CLASSES */
  .mt-0 {
    margin-top: 0;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .mt-1-5 {
    margin-top: 1.5rem;
  }
  .mt-2 {
    margin-top: 2rem;
  }
  .ml-auto {
    margin-left: auto;
  }
  .mr-auto {
    margin-right: auto;
  }
  .mb-0 {
    margin-bottom: 0;
  }

  /* CUSTOM TEXT CLASSES */
  .text-align-center {
    text-align: center;
  }
  .font-3 {
    font-size: 3rem;
  }
  .font-2 {
    font-size: 2rem;
  }

  /* CUSTOM WIDTH CLASSES */
  .width-75 {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .width-50 {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${variables.sm}) {
    .width-75, .width-50 {
      width: 100%;
    }
  }

  /* GRID CHILD POSITIONING */
  .justify-self-start {
    justify-self: start;
  }
  .align-self-center {
    align-self: center;
  }
`;

export default CaseStudyStyles;
