import styled from 'styled-components';

const CTAButton = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: ${props => props.align ? props.align : 'center'};
  a {
    color: ${props => props.secondary};
    background: ${props => props.primary};
    padding: 0.5rem 1rem;
    border-radius: 500px;
    text-align: center;
    span {
      transition: transform 0.2s;
      display: inline-block;
    }
    &:hover {
      span {
        transform: rotate(-45deg);
      }
    }
  }
`;

export default CTAButton;