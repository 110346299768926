import styled from 'styled-components';
import { variables } from '../GlobalStyles';

const EmphasisBlock = styled.div`
  margin: 2rem auto;
  background: ${props => props.brandColor100}; 
  border-radius: ${variables.brstandard};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  p {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${props => props.brandColor900};
    margin: 0;
  }
`;

export default EmphasisBlock;