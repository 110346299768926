import React from 'react';

import GlobalStyle, { variables } from '../styles/GlobalStyles'

import StyledTableOfContents from '../styles/case-study/TableOfContents'
import CaseStudyStyles from '../styles/case-study/CaseStudy'
import CaseStudyHero from '../styles/case-study/HeroBanner'
import { Grid, GridList } from '../styles/case-study/Grid';
import { Card, BoxCard, TableCard } from '../styles/case-study/Card';
import CTAButton from '../styles/case-study/CTAButton';
import EmphasisBlock from '../styles/case-study/EmphasisBlock';
import { Summary, SummaryItem } from '../styles/case-study/Summary';
import CodeBlock from '../styles/case-study/CodeBlock';
import RelatedCarousel from '../components/caseStudyComponents/relatedCarousel';
import ColorSwatch from '../components/caseStudyComponents/colorSwatch';

import SEO from '../components/seo'
import Nav from '../components/nav'
import Wrapper from '../styles/Wrapper'
import { Section } from '../styles/Section';
import Footer from '../components/footer';


const IndexPage = () => {
  return (
    <>
      <GlobalStyle />
      {/* eslint-disable-next-line */}
      <SEO title="Jonathan Hoy - Design System" />
      <header>
        <Nav casestudy={true} />
        <StyledTableOfContents brandColor900={`${variables.dsBrandColor900}`} brandColor100={`${variables.dsBrandColor100}`} className='sidebar'>
          <Wrapper>
            <ol>
              <li><a href="#introduction"><span className='num'>1. </span>Introduction</a></li>
              <li><a href="#size"><span className='num'>2. </span>Size and Spacing</a></li>
              <li><a href="#typography"><span className='num'>3. </span>Typography</a></li>
              <li><a href="#wrappers"><span className='num'>4. </span>Wrappers</a></li>
              <li><a href="#grids"><span className='num'>5. </span>Grids</a></li>
              <li><a href="#colors"><span className='num'>6. </span>Colors</a></li>
              <li><a href="#components"><span className='num'>7. </span>Components</a></li>
            </ol>
          </Wrapper>
        </StyledTableOfContents>
      </header>
      <main className='case-study'>
        <CaseStudyHero src="/case-study/design-system/hero-banner.png" alt="Banner with the text, Case Study Design System."></CaseStudyHero>
        <Section>
          <Wrapper>
            <CaseStudyStyles brandColor900={`${variables.dsBrandColor900}`} brandColor100={`${variables.dsBrandColor100}`} heading={`${variables.dsBrandColor100}`}>
              <div className='text-container' id='introduction'>
                <h2><span className='number'>1</span>Introduction</h2>
                <Summary columns="3">
                  <SummaryItem rows="1 / 3" columns="1 / 3">
                    <p className='heading'>The Project</p>
                    <p className='description'>Documenting and maintaining a design system for my case studies. Design systems are a specific area of interest and something I want to continue to grow in.</p>
                    <p className='description'>Detail, documentation, organization, clarity, communication - these are all things I think design systems should strive to excel in and so this miniature design system is my attempt at doing so.</p>
                  </SummaryItem>
                  <SummaryItem rows="1 / 2" columns="3 / 4">
                    <p className='heading'>My Role</p>
                    <p className='description'>UX/UI Designer</p>
                  </SummaryItem>
                  <SummaryItem rows="2 / 3" columns="3 / 4">
                    <p className='heading'>Tools</p>
                    <p className='description'>Figma</p>
                  </SummaryItem>
                </Summary>
                <CTAButton primary={`${variables.dsBrandColor100}`} secondary={`${variables.white}`}>
                  <a href="https://www.figma.com/file/mMJv0Lg0WqXnlJx4jSAUdX/Case-Study-Design-System?type=design&node-id=1%3A21&mode=design&t=svNXcaSBknaYSQzC-1" target="_blank" rel="noreferrer">View design system in Figma <span className='arrow'>→</span></a>
                </CTAButton>
              </div>


              <div className='text-container' id='size'>
                <h2><span className='number'>2</span>Size and Spacing</h2>
                <h3 className='subheading'>Grid Scale</h3>
                <Grid columns="2">
                  <img src="/case-study/design-system/grid-scale.png" alt=""></img>
                  <p className='mt-0'>Case studies use a <span className='bold'>4px grid scale</span> with a <span className='bold'>base font size of 16px</span>.</p>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Units of Measure</h3>
                <Grid columns="2">
                  <TableCard className='align-self-center'>
                    <tr>
                      <th><span className='bold'>REM</span></th>
                      <td>0.25rem</td>
                      <td>0.5rem</td>
                      <td>0.75rem</td>
                      <td><span className='bold'>1rem</span></td>
                      <td>1.25rem</td>
                      <td>1.5rem</td>
                      <td>1.75rem</td>
                      <td>2rem</td>
                    </tr>
                    <tr>
                      <th><span className='bold'>Pixel</span></th>
                      <td>4px</td>
                      <td>8px</td>
                      <td>12px</td>
                      <td><span className='bold'>16px</span></td>
                      <td>20px</td>
                      <td>24px</td>
                      <td>28px</td>
                      <td>32px</td>
                    </tr>
                  </TableCard>
                  <div>
                    <p><span className='bold'>REM</span> is the default unit with <span className='bold'>1rem equalling the base font size of 16px</span>.</p>
                    <p>A base increment/decrement is <span className='bold'>0.25rem</span>, for example 1.25rem, 1.5rem, 0.75rem, 0.5rem. If we consider this to be 1 increment or 1 decrement, then <span className='bold'>half-increments</span> or <span className='bold'>half-decrements</span> can be used but should be used sparingly.</p>
                    <p>A half-increment is 0.125rem (0.25rem / 2). For example, 0.125rem, 0.875rem.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container' id='typography'>
                <h2><span className='number'>3</span>Typographic Scale</h2>
                <img src="/case-study/design-system/typographic-scale.png" alt=""></img>
              </div>


              <div className='text-container' id='wrappers'>
                <h2><span className='number'>4</span>Site Wrappers</h2>
                <h3 className='subheading'>Desktop Wrapper</h3>
                <img src="/case-study/design-system/desktop-wrapper.png" alt=""></img>
                <Grid columns="2">
                  <CodeBlock>
                    <p><span className='property'>max-width</span>: 1200px;</p>
                    <p><span className='property'>width</span>: calc(100% - 4rem);</p> 
                    <p><span className='property'>margin</span>: 0 auto;</p> 
                  </CodeBlock>
                  <div>
                    <p className='mt-0'>On desktop devices the wrapper has a maximum width of 1200px. This means that content can <span className='bold'>never expand past 1200px in width</span> regardless of how large a screen may be. There are a few exceptions to this rule and those are mainly site layout components. The only relevant component is the hero banner used for case studies. This component will be edge-to-edge of the screen with no regard for the 1200px maximum width.</p>
                    <p>As devices shrink in size to screens less than 1200px wide, this value changes to 100% of the screen’s width minus 2rem (32px) on each side. The wrapper always centers the content.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Tablet Wrapper</h3>
                <Grid columns="2">
                  <img src="/case-study/design-system/tablet-wrapper.png" alt="" className='width-75'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>width</span>: calc(100% - 4rem);</p> 
                      <p><span className='property'>margin</span>: 0 auto;</p> 
                    </CodeBlock>
                    <div>
                      <p>On tablets, the maximum width of 1200px is no longer being considered.</p>
                      <p>In this example, the wrapper is 756px wide but that’s not the important part. What’s important is the 32px (2rem) on either side - to add up to 48px (4rem) in total to give the wrapper and content margin on either side.</p>
                    </div>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Mobile Wrapper</h3>
                <Grid columns="2">
                  <img src="/case-study/design-system/mobile-wrapper.png" alt="" className='width-50'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>width</span>: calc(100% - 2rem);</p> 
                      <p><span className='property'>margin</span>: 0 auto;</p> 
                    </CodeBlock>
                    <div>
                      <p>The wrapper’s behaviour is similar to that of tablets. However, for mobile screens the wrapper takes up 100% of the screen’s width minus 16px (1rem) on either side to add up to 32px (2rem) in total.</p>
                    </div>
                  </div>
                </Grid>
              </div>

              <div className='text-container' id='grids'>
                <h2><span className='number'>5</span>Grids</h2>
                <h3 className='subheading'>Grid Spacing</h3>
                <p>For case study pages most content is 100% in width to the full extent that the wrapper allows - 1200px. The bulk of any case study page consists of body copy and design artifacts, which are both full width.</p>
                <p>However, there is a grid system that can be used in sections for cards and card-like content. Cards are short, digestible pieces of information that would mostly be text and not a visual artifact.</p>
                <img src="/case-study/design-system/grid-spacing-1.png" alt="" className='mt-1-5'></img>
                <Grid columns="2">
                  <CodeBlock>
                    <p><span className='property'>display</span>: grid;</p>
                    <p><span className='property'>grid-template-columns</span>: repeat(3, 1fr);</p> 
                    <p><span className='property'>grid-gap</span>: 2rem;</p> 
                  </CodeBlock>
                  <div>
                    <p className='mt-0'>This is a 3-column grid. The width of a card in this case is approximately 379px when content is extended to the full 1200px on desktop. However that number is not so important as ensuring there is 2rem (32px) spacing (grid gap) for both columns and rows.</p>
                    <p>The truth is, 1200 - 32 - 32 = 1136 / 3 = 378.6666~ which isn’t a nice round number, but it’s okay! As long as the spacing between cards is a consistent 2rem, the width of cards themselves do not matter.</p>
                    <p>Now that I think about it, setting the wrapper to 1204px would result in even numbers but as I mentioned, the width of the card here is not so important as the consistent spacing between elements in the grid.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <img src="/case-study/design-system/grid-spacing-2.png" alt=""></img>
                <Grid columns="2">
                  <CodeBlock>
                    <p><span className='property'>display</span>: grid;</p>
                    <p><span className='property'>grid-template-columns</span>: repeat(2, 1fr);</p> 
                    <p><span className='property'>grid-gap</span>: 2rem;</p> 
                  </CodeBlock>
                  <div>
                    <p className='mt-0'>2-column variant. What determines whether a grid should be 3 columns or 2 columns depends on the amount of content in each card.</p>
                    <p>At the time of writing, there is no 4-column grid present on case study pages. There is no rule against it, but at that point the content in each card would have to be quite limited. In practice, 2-column and 3-column grids cover most use-cases.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Grid Use-cases</h3>
                <p>The grid itself is concerned with <span className='bold'>layout</span>. The contents of a grid can be flexible. Cards, text, images, and videos can all be placed in a grid.</p>
                <img src="/case-study/design-system/grid-use-cases.png" alt="" className='mt-1-5'></img>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Grid Responsiveness</h3>
                <p>Regardless of the number of columns a grid has, on tablets the grid should <span className='bold'>re-size to 2 columns</span>. On mobile devices, the grid should <span className='bold'>resize to 1 column</span>.</p>
                <h4 className='subheading'>Desktop</h4>
                <Grid columns="2">
                  <img src="/case-study/design-system/grid-responsive-desktop.png" alt="" className='width-75'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>display</span>: grid;</p>
                      <p><span className='property'>grid-template-columns</span>: repeat(3, 1fr);</p> 
                      <p><span className='property'>grid-gap</span>: 2rem;</p> 
                    </CodeBlock>
                    <p>This is a 3-column grid on desktop. This example is showing how a 3-column grid responsively scales down. This is true for a 2-column grid, 4-column grid, or any other number of columns in a grid.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Tablet</h4>
                <Grid columns="2">
                  <img src="/case-study/design-system/grid-responsive-tablet.png" alt="" className='width-50'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>display</span>: grid;</p>
                      <p><span className='property'>grid-template-columns</span>: repeat(2, 1fr);</p> 
                      <p><span className='property'>grid-gap</span>: 2rem;</p> 
                    </CodeBlock>
                    <p>That same 3-column grid on desktop will resize to 2 columns on tablet.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Mobile</h4>
                <Grid columns="2">
                  <img src="/case-study/design-system/grid-responsive-mobile.png" alt="" className='width-50'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>display</span>: grid;</p>
                      <p><span className='property'>grid-template-columns</span>: repeat(1, 1fr);</p> 
                      <p><span className='property'>grid-gap</span>: 1rem;</p> 
                    </CodeBlock>
                    <p>On mobile, the 3-column grid will resize to 1 column.</p>
                    <p>Note that the grid gap has also been reduced from 2rem to 1rem (16px) on mobile.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container' id='colors'>
                <h2><span className='number'>6</span>Colors</h2>
                <h3 className='subheading'>Common Colors</h3>
                <GridList columns="6">
                  <ColorSwatch name="Grey 50" hex="#F7F7F7" />
                  <ColorSwatch name="Grey 100" hex="#F5F5F5" />
                  <ColorSwatch name="Grey 200" hex="#E8E8E8" />
                  <ColorSwatch name="Grey 400" hex="#C0C0C0" />
                  <ColorSwatch name="Grey 800" hex="#919191" />
                  <ColorSwatch name="Grey 900" hex="#656565" />
                  <ColorSwatch name="Black" hex="#222" />
                  <ColorSwatch name="White" hex="#FFF" outline="#222" />
                  <ColorSwatch name="Semantic Success 100" hex="#D6F5E1" />
                  <ColorSwatch name="Semantic Success 900" hex="#6FDD97" />
                  <ColorSwatch name="Semantic Error 100" hex="#F5D7CC" />
                  <ColorSwatch name="Semantic Error 900" hex="#E15623" />
                </GridList>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Safely Case Study Colors</h3>
                <GridList columns="6">
                  <ColorSwatch name="Safely Brand Color 1" hex="#D9E9F7" />
                  <ColorSwatch name="Safely Brand Color 2" hex="#123959" />
                </GridList>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Apple Music Case Study Colors</h3>
                <GridList columns="6">
                  <ColorSwatch name="Apple Music Brand Color 1" hex="#F94C57" />
                  <ColorSwatch name="Apple Music Brand Color 2" hex="#FFF" outline="#222" />
                </GridList>
              </div>

              <div className='text-container' id='components'>
                <h2><span className='number'>7</span>Components</h2>
                <h3 className='subheading'>Bordered Card</h3>
                <img src="/case-study/design-system/component-bordered-card-1.png" alt="" className='width-75 mt-1-5'></img>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Spacing</h4>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-bordered-card-2.png" alt="" className=''></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>padding</span>: 1.5rem;</p>
                      <p><span className='property'>background</span>: $&#123;grey100&#125;;</p> 
                      <p><span className='property'>border-radius</span>: 0.75rem;</p> 
                    </CodeBlock>
                    <p>The Bordered Card has consistent padding around the component but the spacing (margin) between elements depends on the type of element (explained later).</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Variations</h4>
                <Grid columns="2">
                  <div>
                    <BoxCard>
                      <p className='headline'>1</p>
                      <p className='description'>The existing friends list is not an easily discoverable feature and the flow to view the friends list is lengthy.</p>
                    </BoxCard>
                    <p>Headline and description.</p>
                  </div>
                  <div>
                    <BoxCard>
                      <p className='heading'>Make the friends feature a prominent feature.</p>
                      <p className='description'>The existing friends list is not an easily discoverable feature and the flow to view the friends list is lengthy.</p>
                    </BoxCard>
                    <p>Heading and description.</p>
                  </div>
                  <div>
                    <BoxCard>
                      <p className='description'>The existing friends list is not an easily discoverable feature and the flow to view the friends list is lengthy.</p>
                    </BoxCard>
                    <p>Description only.</p>
                  </div>
                </Grid>
                <p>Cards (both bordered and non-bordered) can have up to three elements: a headline, a heading, and a description. A card doesn’t need to have all three elements but each serve a different purpose.</p>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Anatomy</h4>
                <h5>Headline</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-bordered-card-anatomy-1.png" alt="" className=''></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>font-weight</span>: 700;</p>
                      <p><span className='property'>font-size</span>: 2rem;</p> 
                      <p><span className='property'>margin-top</span>: 0;</p> 
                      <p><span className='property'>margin-bottom</span>: 1.5rem;</p> 
                    </CodeBlock>
                    <p>Headlines should be reserved for a few characters only. Big, punchy numbers or emojis to grab the reader’s attention.</p>
                  </div>
                </Grid>
              </div>
              
              <div className='text-container'>
                <h5>Heading</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-bordered-card-anatomy-2.png" alt="" className=''></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>font-weight</span>: 600;</p>
                      <p><span className='property'>font-size</span>: 1.25rem;</p> 
                      <p><span className='property'>margin-top</span>: 0;</p> 
                      <p><span className='property'>margin-bottom</span>: 1rem;</p> 
                    </CodeBlock>
                    <p>The heading should deliver the main insight of a card, unless that would be too long. Generally the heading should be kept to two lines or less.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h5>Description</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-bordered-card-anatomy-3.png" alt="" className=''></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>font-weight</span>: 300;</p>
                      <p><span className='property'>font-size</span>: 1rem;</p> 
                      <p><span className='property'>margin-top</span>: 0;</p> 
                      <p><span className='property'>margin-bottom</span>: 0;</p> 
                    </CodeBlock>
                    <p>The description can further explain the heading or in some cases be the sole content of a card. Additional context may be required.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Colors</h4>
                <Grid columns="2" padding="1.5rem">
                  <BoxCard>
                    <p className='headline'>1</p>
                    <p className='heading'>Make the friends feature a prominent feature.</p>
                    <p className='description'>The existing friends list is not an easily discoverable feature and the flow to view the friends list is lengthy.</p>
                  </BoxCard>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>background</span>: $&#123;grey100&#125;;</p>
                    </CodeBlock>
                    <p>The default background color of <span className='bold'>&#123;grey100&#125;</span> is typically used.</p>
                  </div>
                </Grid>
                <Grid columns="2" background="grey100" padding="1.5rem">
                  <BoxCard background="white">
                    <p className='headline'>1</p>
                    <p className='heading'>Make the friends feature a prominent feature.</p>
                    <p className='description'>The existing friends list is not an easily discoverable feature and the flow to view the friends list is lengthy.</p>
                  </BoxCard>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>background</span>: $&#123;white&#125;;</p>
                    </CodeBlock>
                    <p>The background color of the card can be set to <span className='bold'>&#123;white&#125;</span> when used on non-white backgrounds.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Non-bordered Card</h3>
                <img src="/case-study/design-system/component-non-bordered-card-1.png" alt="" className='width-75 mt-1-5'></img>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Spacing</h4>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-non-bordered-card-2.png" alt="" className=''></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>padding</span>: 0;</p>
                      <p><span className='property'>background</span>: none;</p> 
                      <p><span className='property'>border-radius</span>: 0;</p> 
                    </CodeBlock>
                    <p>In relation to the Bordered Card, the Non-bordered Card removes the padding and border radius. The Non-bordered Card <span className='bold'>maintains the same spacing between elements in the card as the Bordered Card</span>.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Variations</h4>
                <Grid columns="2">
                  <Grid columns="1">
                    <Card>
                      <p className='headline'>1</p>
                      <p className='description'>The existing friends list is not an easily discoverable feature and the flow to view the friends list is lengthy.</p>
                    </Card>
                    <Card className='border-top-1'>
                      <p className='heading'>Make the friends feature a prominent feature.</p>
                      <p className='description'>The existing friends list is not an easily discoverable feature and the flow to view the friends list is lengthy.</p>
                    </Card>
                    <Card className='border-top-1'>
                      <p className='description'>The existing friends list is not an easily discoverable feature and the flow to view the friends list is lengthy.</p>
                    </Card>
                  </Grid>
                  <p>The same variations as a bordered card can exist.</p>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Section Numbers</h3>
                <Grid columns="2">
                  <Grid columns="10" gap="0">
                    <h2><span className='number'>1</span></h2>
                    <h2><span className='number'>2</span></h2>
                    <h2><span className='number'>3</span></h2>
                    <h2><span className='number'>4</span></h2>
                    <h2><span className='number'>5</span></h2>
                    <h2><span className='number'>6</span></h2>
                    <h2><span className='number'>7</span></h2>
                    <h2><span className='number'>8</span></h2>
                    <h2><span className='number'>9</span></h2>
                  </Grid>
                  <CodeBlock>
                    <p><span className='property'>display</span>: flex;</p>
                    <p><span className='property'>justify-content</span>: center;</p>
                    <p><span className='property'>align-items</span>: center;</p>
                    <p><span className='property'>width</span>: 2rem;</p>
                    <p><span className='property'>height</span>: 2rem;</p>
                    <p><span className='property'>border-radius</span>: 0.25rem;</p>
                  </CodeBlock>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Character Properties & Length</h4>
                <Grid columns="2">
                  <Grid columns="1" className='mt-0 mb-0'>
                    <Grid columns="1" gap="0" className='mt-0 mb-0'>
                      <Grid columns="10" gap="0" className='mt-0 mb-0'>
                        <h2><span className='number'>1</span></h2>
                        <h2><span className='number'>2</span></h2>
                        <h2><span className='number'>3</span></h2>
                        <h2><span className='number'>4</span></h2>
                        <h2><span className='number'>5</span></h2>
                        <h2><span className='number'>6</span></h2>
                        <h2><span className='number'>7</span></h2>
                        <h2><span className='number'>8</span></h2>
                        <h2><span className='number'>9</span></h2>
                        <h2><span className='number'>10</span></h2>
                        <h2><span className='number'>11</span></h2>
                        <h2><span className='number'>12</span></h2>
                        <h2><span className='number'>13</span></h2>
                        <h2><span className='number'>14</span></h2>
                        <h2><span className='number'>15</span></h2>
                        <h2><span className='number'>16</span></h2>
                        <h2><span className='number'>17</span></h2>
                        <h2><span className='number'>18</span></h2>
                        <h2><span className='number'>19</span></h2>
                        <h2><span className='number'>20</span></h2>
                      </Grid>
                      <span className='text-align-center font-3'>✅</span>
                    </Grid>
                    <Grid columns="1" gap="0" className='mb-0'>
                      <Grid columns="10" gap="0" className='mt-0 mb-0'>
                        <h2><span className='number'>111</span></h2>
                        <h2><span className='number'>222</span></h2>
                        <h2><span className='number'>333</span></h2>
                        <h2><span className='number'>444</span></h2>
                        <h2><span className='number'>555</span></h2>
                        <h2><span className='number'>666</span></h2>
                        <h2><span className='number'>777</span></h2>
                        <h2><span className='number'>888</span></h2>
                        <h2><span className='number'>999</span></h2>
                      </Grid>
                      <span className='text-align-center font-3'>❌</span>
                    </Grid>
                  </Grid>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>font-size</span>: 1.25rem;</p>
                      <p><span className='property'>font-weight</span>: 500;</p>
                    </CodeBlock>
                    <p>Text style: <span className='bold'>Section Heading</span> (see typographic scale)</p>
                    <p>Because width and height is <span className='bold'>implicitly</span> set to 32px this component will never grow or shrink. It will run into problems if the character length is too long. However given the limited use-case of numbering sections, we can reasonably presume there will not be any triple character instances.</p>
                    <p>Padding between the number and the border of the box is not relevant.</p>
                  </div>
                </Grid>
              </div>
              

              <div className='text-container'>
                <h4 className='subheading'>Colors</h4>
                <Grid columns="2">
                  <div>
                    <Grid columns="10" gap="0" className='mt-0'>
                      <h2><span className='number'>1</span></h2>
                      <h2><span className='number'>2</span></h2>
                      <h2><span className='number'>3</span></h2>
                      <h2><span className='number'>4</span></h2>
                      <h2><span className='number'>5</span></h2>
                      <h2><span className='number'>6</span></h2>
                      <h2><span className='number'>7</span></h2>
                      <h2><span className='number'>8</span></h2>
                      <h2><span className='number'>9</span></h2>
                    </Grid>
                    <CaseStudyStyles brandColor900={`${variables.safelyBrandColor900}`} brandColor100={`${variables.safelyBrandColor100}`} heading={`${variables.safelyBrandColor900}`}>
                      <Grid columns="10" gap="0" className='mt-0'>
                        <h2><span className='number'>1</span></h2>
                        <h2><span className='number'>2</span></h2>
                        <h2><span className='number'>3</span></h2>
                        <h2><span className='number'>4</span></h2>
                        <h2><span className='number'>5</span></h2>
                        <h2><span className='number'>6</span></h2>
                        <h2><span className='number'>7</span></h2>
                        <h2><span className='number'>8</span></h2>
                        <h2><span className='number'>9</span></h2>
                      </Grid>
                    </CaseStudyStyles>
                    <CaseStudyStyles brandColor900={`${variables.amBrandColor900}`} brandColor100={`${variables.amBrandColor100}`} heading={`${variables.black}`}>
                      <Grid columns="10" gap="0">
                        <h2><span className='number'>1</span></h2>
                        <h2><span className='number'>2</span></h2>
                        <h2><span className='number'>3</span></h2>
                        <h2><span className='number'>4</span></h2>
                        <h2><span className='number'>5</span></h2>
                        <h2><span className='number'>6</span></h2>
                        <h2><span className='number'>7</span></h2>
                        <h2><span className='number'>8</span></h2>
                        <h2><span className='number'>9</span></h2>
                      </Grid>
                    </CaseStudyStyles>
                  </div>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>color</span>: $&#123;brandColor2&#125;;</p>
                      <p><span className='property'>background</span>: $&#123;brandColor1&#125;;</p>
                    </CodeBlock>
                    <img src="/case-study/design-system/dynamic-color.png" alt="" className='dynamic-color mt-1-5 ml-auto mr-auto'></img>
                    <p>This component’s colours are <span className='bold'>dynamic</span>, meaning the colour of the element and background colour will vary from case study to case study. Case study colours are determined in larger, global components and passed down to specific components such as this one.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h3 className='subheading'>Links</h3>
                <h4 className='subheading'>Inline Link</h4>
                <h5>Default State</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-inline-link-default.png" alt="" className='mr-auto ml-auto'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>padding</span>: 0.125rem 0.5rem;</p>
                      <p><span className='property'>border-radius</span>: 0.25rem;</p>
                      <p><span className='property'>background</span>: $&#123;grey100&#125;;</p>
                    </CodeBlock>
                    <p>Text style: <span className='bold'>Link</span> (see typographic scale)</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h5>Hover State</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-inline-link-hover.png" alt="" className='mr-auto ml-auto'></img>
                  <CodeBlock>
                    <p><span className='property'>transition</span>:background 0.2s;</p>
                    <p><span className='property'>background</span>: $&#123;grey200&#125;;</p>
                    <br />
                    {/* eslint-disable-next-line */}
                    <p><span className='comment'>// arrow</span></p>
                    <p><span className='property'>transform</span>: rotate(-45deg);</p>
                  </CodeBlock>
                </Grid>
              </div>

              <div className='text-container'>
                <h5>Size and Spacing</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-inline-link-sas.png" alt="" className=''></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>padding</span>: 0.125rem 0.5rem;</p>
                      <p><span className='property'>border-radius</span>: 0.25rem;</p>
                    </CodeBlock>
                    <p>The inline link should be used in body text, as opposed to a CTA link which would occupy its own line.</p>
                    <p>When hovered over, the arrow should rotate 45 degrees counter-clockwise and the background should transition from <span className='bold'>&#123;grey100&#125;</span> to <span className='bold'>&#123;grey200&#125;</span>. All animations should occur in 0.2s.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>CTA Link</h4>
                <h5>Default State</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-cta-link-default.png" alt="" className='width-75'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>padding</span>: 0.5rem 1rem;</p>
                      <p><span className='property'>border-radius</span>: 3rem;</p>
                    </CodeBlock>
                    <p>Text style: <span className='bold'>Link</span> (see typographic scale)</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h5>Hover State</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-cta-link-hover.png" alt="" className='width-75'></img>
                  <CodeBlock>
                    {/* eslint-disable-next-line */}
                    <p><span className='comment'>// arrow</span></p>
                    <p><span className='property'>transform</span>: rotate(-45deg);</p>
                  </CodeBlock>
                </Grid>
              </div>

              <div className='text-container'>
                <h5>Size and Spacing</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-cta-link-sas.png" alt="" className=''></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>padding</span>: 0.5rem 1rem;</p>
                      <p><span className='property'>border-radius</span>: 3rem;</p>
                    </CodeBlock>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h5>Colors</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-cta-link-colors.png" alt="" className=''></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>color</span>: $&#123;white&#125;;</p>
                      <p><span className='property'>background</span>: $&#123;brandColor1&#125; || $&#123;brandColor2&#125;;</p>
                    </CodeBlock>
                    <img src="/case-study/design-system/dynamic-color.png" alt="" className='dynamic-color mt-1-5 ml-auto mr-auto'></img>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h5>Width</h5>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-link-width.png" alt="" className='width-50'></img>
                  <p>Width is not explicitly set on any of the link styles. Width is determined by the length of the content inside the link along with the padding that has been set.</p>
                </Grid>
              </div>


              <div className='text-container'>
                <h3 className='subheading'>Emphasis Block</h3>
                <EmphasisBlock brandColor100={`${variables.dsBrandColor100}`} brandColor900={`${variables.dsBrandColor900}`}>
                  <p>Apple Music users that enjoy listening to music, discovering new music, and talking to their friends about music have the problem that they want to know what music their friends are listening to. My solution should deliver the ability for users to learn about their friends' music tastes and discuss with friends the music that they have in common.</p>
                </EmphasisBlock>
                <Grid columns="2">
                  <div>
                    <CodeBlock>
                      <p><span className='property'>padding</span>: 2rem;</p>
                      <br/>
                      <p><span className='property'>font-size</span>: 1.25rem;</p>
                      <p><span className='property'>font-weight</span>: 500;</p>
                    </CodeBlock>
                    <p>Text style: <span className='bold'>Emphasis Block</span> (see typographic scale)</p>
                  </div>
                  <p className='mt-0'>The Emphasis Block is used to emphasize text on a case study page. This component should be used sparingly or else its value would be diminished with repeat use. The Emphasis Block can have multiple paragraphs but the length of text should be considered.</p>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Size and Spacing</h4>
                <img src="/case-study/design-system/component-emphasis-block-sas.png" alt="" className='mt-1-5'></img>
                <Grid columns="2">
                  <CodeBlock>
                    <p><span className='property'>padding</span>: 2rem;</p>
                  </CodeBlock>
                  <p className='mt-0'>The emphasis block is 100% in width, meaning the component will grow and shrink alongside the standard Wrapper. The component will have a maximum width of 1200px and shrink as necessary. The height of the component is dynamic in the sense that the number of lines of text plus top padding and bottom padding will determine the height.</p>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Colors</h4>
                <EmphasisBlock brandColor100={`${variables.amBrandColor100}`} brandColor900={`${variables.amBrandColor900}`}>
                  <p>Apple Music users that enjoy listening to music, discovering new music, and talking to their friends about music have the problem that they want to know what music their friends are listening to. My solution should deliver the ability for users to learn about their friends' music tastes and discuss with friends the music that they have in common.</p>
                </EmphasisBlock>
                <EmphasisBlock brandColor100={`${variables.safelyBrandColor100}`} brandColor900={`${variables.safelyBrandColor900}`}>
                  <p>I believe language barriers create skepticism or doubts in people with severe food allergies that prevent them from trusting the local cuisine while abroad.</p>
                  <p>I will know this to be true when the majority of my interview respondents acknowledge that language barriers have been a factor that have caused hesitation in choosing a travel destination.</p>
                </EmphasisBlock>
                <Grid columns="2">
                  <div>
                    <CodeBlock>
                      <p><span className='property'>color</span>: $&#123;brandColor2&#125;;</p>
                      <p><span className='property'>background</span>: $&#123;brandColor1&#125;;</p>
                    </CodeBlock>
                    <img src="/case-study/design-system/dynamic-color.png" alt="" className='dynamic-color mt-1-5 ml-auto mr-auto'></img>
                  </div>
                </Grid>
              </div>


              <div className='text-container'>
                <h3 className='subheading'>Summary Card</h3>
                <Summary columns="3">
                  <SummaryItem rows="1 / 2" columns="1 / 4">
                    <p className='heading'>The Project</p>
                    <p className='description'>Academic project for BrainStation's UX Design Diploma program. The problem space is concerning travelers with severe food allergies. These individuals face fear and anxiety when traveling abroad due to uncertainties regarding the local cuisine as it relates to their allergies.</p>
                    <p className='description'>This solution allows travelers with food allergies to travel abroad, experience new cultures, and navigate around local cuisines. This is an individual case study spanning the design process from problem discovery, definition, developing, and design iteration.</p>
                  </SummaryItem>
                  <SummaryItem rows="2 / 3" columns="1 / 2">
                    <p className='heading'>My Roles</p>
                    <ul className='list no-bullet small-mt'>
                      <li><p>UX Researcher</p></li>
                      <li><p>UX/UI Designer</p></li>
                    </ul>
                  </SummaryItem>
                  <SummaryItem rows="2 / 3" columns="2 / 3">
                    <p className='heading'>Timeline</p>
                    <p className='description'>3 months</p>
                  </SummaryItem>
                  <SummaryItem rows="2 / 3" columns="3 / 4">
                    <p className='heading'>Tools</p>
                    <ul className='list no-bullet small-mt'>
                      <li><p>Figma</p></li>
                      <li><p>Figjam</p></li>
                      <li><p>InVision</p></li>
                    </ul>
                  </SummaryItem>
                </Summary>
                <Grid columns="2">
                  <div>
                    <CodeBlock>
                      {/* eslint-disable-next-line */}
                      <p><span className='comment'>// Card Heading</span></p>
                      <br/>
                      <p><span className='property'>font-size</span>: 1.25rem;</p>
                      <p><span className='property'>font-weight</span>: 600;</p>
                      <p><span className='property'>margin-top</span>: 0;</p>
                      <p><span className='property'>margin-bottom</span>: 1rem;</p>
                    </CodeBlock>
                    <CodeBlock className='mt-1-5'>
                      {/* eslint-disable-next-line */}
                      <p><span className='comment'>// Card Description</span></p>
                      <br/>
                      <p><span className='property'>font-size</span>: 1rem;</p>
                      <p><span className='property'>font-weight</span>: 300;</p>
                      <p><span className='property'>margin-top</span>: 0;</p>
                      <p><span className='property'>margin-bottom</span>: 0;</p>
                    </CodeBlock>
                  </div>
                  <div>
                    <p className=''>The Summary Card builds off of the design, layout, and spacing of the Bordered Card. It is modular and uses a grid to arrange different types of content within it. Text styles are the same as the Bordered Card, that is the <span className='bold'>Card Heading</span> and <span className='bold'>Card Description</span>.</p>
                    <p>Unlike Cards, the Summary Card should only ever be used at full width and not in a grid with other cards.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Size and Spacing</h4>
                <img src="/case-study/design-system/component-summary-card-sas.png" alt="" className='mt-1-5'></img>
                <Grid columns="2">
                  <CodeBlock>
                    <p><span className='property'>padding</span>: 1.5rem;</p>
                    <p><span className='property'>background</span>: $&#123;grey100&#125;;</p> 
                    <p><span className='property'>border-radius</span>: 0.75rem;</p> 
                  </CodeBlock>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Grid Spacing</h4>
                <img src="/case-study/design-system/component-summary-card-grid-spacing.png" alt="" className='mt-1-5'></img>
                <Grid columns="2">
                  <CodeBlock>
                    <p><span className='property'>display</span>: grid;</p>
                    <p><span className='property'>grid-template-columns</span>: repeat(3, 1fr);</p> 
                    <p><span className='property'>grid-gap</span>: 2rem;</p> 
                  </CodeBlock>
                  <p className='mt-0'>A standard grid is used here. Current use-cases are 3-column grids but the case can be made for 4-column or even 5-column grids if the content isn’t too text-heavy.</p>
                </Grid>
              </div>

            
              <div className='text-container'>
                <h3 className='subheading'>Table of Contents</h3>
                <h4 className='subheading'>Default State</h4>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-toc-default.png" alt="" className='mr-auto ml-auto'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>padding</span>: 0.125rem 0.5rem;</p>
                      <p><span className='property'>border-radius</span>: 0.25rem;</p>
                      <p><span className='property'>font-size</span>: 0.875rem;</p>
                      <p><span className='property'>text-decoration</span>: none;</p>
                    </CodeBlock>
                    <p>Text style: <span className='bold'>Table of Contents Link</span> (see typographic scale)</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Hover State</h4>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-toc-hover.png" alt="" className='mr-auto ml-auto'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>color</span>: $&#123;brandColor2&#125;;</p>
                      <p><span className='property'>background</span>: $&#123;brandColor1&#125;;</p>
                    </CodeBlock>
                    <img src="/case-study/design-system/dynamic-color.png" alt="" className='dynamic-color mt-1-5 ml-auto mr-auto'></img>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Size and Spacing</h4>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-toc-sas.png" alt="" className='mr-auto ml-auto'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>padding</span>: 0.125rem 0.5rem;</p>
                      <p><span className='property'>border-radius</span>: 0.25rem;</p>
                    </CodeBlock>
                    <p>Like other links, the size of each table of contents link is determined by the length of the content of the link plus padding.</p>
                    <p>Padding is put on links even in their default states. This ensures the size of the link is consistent in all states as only colours are changing.</p>
                  </div>
                </Grid>
              </div>

              <div className='text-container'>
                <h4 className='subheading'>Table of Contents List Spacing</h4>
                <Grid columns="2">
                  <img src="/case-study/design-system/component-toc-grid.png" alt="" className='mr-auto ml-auto'></img>
                  <div>
                    <CodeBlock>
                      <p><span className='property'>display</span>: flex;</p>
                      <p><span className='property'>gap</span>: 0.5rem;</p>
                    </CodeBlock>
                    <p>The Tablet of Contents layout can be best achieved using <span className='bold'>Flexbox</span>. In any case, there should be a 8px (0.5rem) gap consistently between links. This gap is <span className='bold'>in addition to</span> the padding of each link.</p>
                  </div>
                </Grid>
              </div>

            </CaseStudyStyles>
          </Wrapper>
        </Section>
        <RelatedCarousel doNotInclude="design-system"/>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default IndexPage;

