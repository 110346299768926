import styled from 'styled-components';
import { variables } from '../GlobalStyles';

const Card = styled.div`
  .headline {
    font-weight: 700;
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
  .heading {
    font-weight: 600;
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .description {
    margin-top: 0;
    margin-bottom: 0;
  }
  .list {
    padding-left: 1rem;
    margin-bottom: 0;
  }
  .list.no-bullet {
    list-style: none;
    padding: 0;
  }
  .small-mt {
    li {
      p {
        margin-top: 0.25rem;
      }
    }
  }
`;

const BoxCard = styled(Card)`
  background: ${variables.grey100};
  padding: 1.5rem;
  border-radius: ${variables.brstandard};
  ${props => props.background === 'white' && `background: ${variables.white};`};
  @media (max-width: ${variables.md}) {
  }
  @media (max-width: ${variables.sm}) {
    padding: 1rem;
  }
`;

const TableCard = styled.table`
  background: ${variables.grey100};
  border-radius: ${variables.brstandard};
  border-spacing: 1.5rem;
  th, td {
    padding: 1rem 0.5rem;
    text-align: right;
  }
  @media (max-width: ${variables.sm}) {
    display: flex;
    justify-self: center;
    padding: 1.5rem;
    gap: 1rem;
    tr {
      display: flex;
      flex-direction: column;
    }
  }
`;

export { Card, BoxCard, TableCard };