import styled from 'styled-components';
import { variables } from '../GlobalStyles';

const CaseStudyHero = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;
  max-height: 700px;
  margin-top: 6rem;
  
  @media (max-width: ${variables.sm}) {
    margin-top: 0;
  }
  @media (min-width: 2000px) {
    max-height: none;
  }
`;

export default CaseStudyHero;