import React from 'react';
import { Section,SectionHeading } from '../../../styles/Section';
import Wrapper from '../../../styles/Wrapper';
import caseStudyItems from '../../design/data/case-study-items';
import { StyledCardListItem } from '../../../styles/List';
import { GridList } from '../../../styles/case-study/Grid';

const RelatedCarousel = (props) => (
  <Section className='related-carousel'>
    <Wrapper>
      <SectionHeading>Other Projects:</SectionHeading>
      <GridList columns="2">
        {
          caseStudyItems
          /* eslint-disable-next-line */
          .filter((item) => {
            if (item.class !== props.doNotInclude) {
              return item;
            }
          })
          .map((item) => {
            return (
              <StyledCardListItem>
                <a href={item.href} aria-label={item.ariaLabel}>
                  <div className="img-container">
                    <img className="image" src={item.src} alt={item.name}/>
                  </div>
                  <div className="container">
                    <h3 className="heading">{item.name} <span className='arrow'>→</span></h3>
                    <p className="description">{item.description}</p>
                  </div>
                </a>
              </StyledCardListItem>
            )
          })
        }
      </GridList>
    </Wrapper>
  </Section>
);

export default RelatedCarousel;